<template>
  <div id="am-container" style="position:absolute;height:99%;width:99%;margin:auto">
    <div id="arcane-player"
      :data-project-id="projectId"
      :data-project-key="projectKey"
      data-idle-timeout="60"
      data-capture-mouse="false"
      :data-token="token"
      data-enable-events-passthrough="false"
      data-hide-ui-controls="false"
    ></div>
  </div>
</template>

<!-- data-project-id="179"-->
<!-- data-project-key="43b660e9-652f-4ab8-87a4-d127e471f702"-->
<!-- data-token="_wpcu4RV8YQm"-->
<!-- https://stream.sequinar.com?projectId=179&projectKey=43b660e9-652f-4ab8-87a4-d127e471f702&token=_wpcu4RV8YQm -->
<!-- http://localhost:8080/?projectId=179&projectKey=43b660e9-652f-4ab8-87a4-d127e471f702&token=_wpcu4RV8YQm -->
<!-- http://localhost:8080/?projectId=172&projectKey=f267fd99-edbe-48fc-b981-205fbc9c59fa&token=_wpcu4RV8YQm -->

<script>
//import { computed } from 'vue'

function GetURLParameter(sParam)
{
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) 
    {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) 
        {
            return sParameterName[1];
        }
    }

    return "";
}

//const projectKey = computed(() =>{ return GetURLParameter('projectKey')});
//const projectId = computed(() =>{ return GetURLParameter('projectId')});
//const token = computed(() =>{return GetURLParameter('token')});

export default {
  name: 'App',
  components: {
    //HelloWorld
  },
  computed: {
    projectKey: function() {
      return GetURLParameter('projectKey');
    },
    projectId: function() {
      return GetURLParameter('projectId');
    },
    token: function() {
      return GetURLParameter('token');
    }
  },
  mounted() {
    //const router = useRouter();
    //const route = useRoute();

    console.log('Component mounted.')
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://player.arcanemirage.com/1.0.5/player.js');
    recaptchaScript.setAttribute('defer','');
    document.body.appendChild(recaptchaScript);
    
    
    setTimeout(() => {
      // let elements = document.getElementsByTagName('svg');
      // elements[1].style.display='none';
      // console.log(elements);


      //let innerText = document.getElementById('infoOverlay');
      //innerText.parentElement.removeChild(innerText);
      //innerText.style.display='none';

      // let parent = document.getElementById("infoOverlay").parentElement;
      // document.getElementById("infoOverlay").remove();

      // const node = document.createElement("div");
      // node.id = 'infoOverlay';
      // node.className = 'infoOverlay';
      // node.style.width = "100%";
      // node.style.height = "100%";
      // node.style.background = "red";
      // node.style.zIndex=0;
      // node.style.position='absolute';

      // parent.appendChild(node);
    }, 500);
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
svg
{
  display:none;
  
}
svg#maximizeIcon
{
  display:unset !important
}

svg#muteIcon
{
  display:unset !important
}
</style>
