import { createApp } from 'vue'
import App from './App.vue'
//import { VueRouter } from 'vue-router'

const app = createApp(App);

// const router = VueRouter.createRouter({
//     // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
//     history: VueRouter.createWebHashHistory(),
//    // routes: routes, // short for `routes: routes`
//   })
  
//app.use(router)

app.mount('#app');